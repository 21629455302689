//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      
    }
  },
  methods: {
     to(){
       window.open('https://beian.miit.gov.cn/')
     }
  },
};
