//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Hotline from "@/components/hotline.vue";
import Address from "@/components/address.vue";
export default {
  components: {
    Hotline,
    Address,
  },
  data() {
    return {
      popper_class: {
        padding: 0,
      },
      is_index: -1,
      list: [
        {
          name: "品牌",
          son: [
            { name: "品牌故事", url: "/story" },
            { name: "品牌档案", url: "/life" },
          ],
        },
        {
          name: "产品中心",
          url: "/product",
          son: [
            { name: "燕宴颜YANYES元气装", url: "/product" },
            { name: "燕宴颜YANYES尊享装", url: "/product" },
            { name: "燕宴颜YANYES贵宾装", url: "/product" },
            { name: "燕宴颜YANYES豪华家庭装", url: "/product" },
          ],
        },
        {
          name: "燕宴颜生活",
          son: [{ name: "6分钟鲜泡燕窝", url: "/features" }],
        },
        // {
        //   name: "招商代理",
        //   url: "/agent",
        // },
        // {
        //   name: "联系我们",
        //   url: "/contact",
        // },
      ],
    };
  },
  created() {},
  methods: {
    text_click(url, index) {
      this.is_index = index;
      this.$router.push(url);
    },
  },
};
